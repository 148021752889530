import React, { useCallback, useMemo, createContext, useContext, useState, useEffect } from 'react';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import omitBy from 'lodash/omitBy';
import uniqBy from 'lodash/uniqBy';

import { setCookie, getCookie, getVisitorLocationCookie, setVisitorLocationCookie } from 'utils/cookie';
import { useVisitorLocation } from 'utils/hooks/useLocationFilter';
import { sentryCaptureException } from 'utils/globals/sentry';

import { primaryLanguageCodes, allLanguageCodes } from 'constants/languageCodes';

import {
  OB_VISITOR_ID,
  OB_VISITOR_LOCATION,
  LANGUAGE_LAST_ACCESSED,
  LANGUAGE_PREFERRED,
  EXPIRY_TIME,
  OB_VISITOR_REQ_ID,
} from 'constants/cookieConstants';

import { i18n, defaultLanguage } from 'src/i18n';

const useAndSaveVisitorLocation = (visitorData, hasFingerprintFailed = false, serverCookies = {}) => {
  const visitorId = visitorData?.visitorId;
  const savedVisitorID = getCookie(OB_VISITOR_ID) || serverCookies[OB_VISITOR_ID];
  const { city: savedCity, country: savedCountry, region: savedRegion } = getVisitorLocationCookie(
    serverCookies[OB_VISITOR_LOCATION],
  );

  const { shouldFetchLocationDetails, isReady } = useMemo(() => {
    const isMissingSavedLocation = !savedCity?.id && !savedCountry?.id && !savedRegion?.id;

    if (!visitorId) {
      return {
        shouldFetchLocationDetails: false,
        isReady: !isMissingSavedLocation || hasFingerprintFailed,
      };
    }

    const isVisitorIDChanged = !savedVisitorID || visitorId !== savedVisitorID;

    return {
      shouldFetchLocationDetails: isVisitorIDChanged || isMissingSavedLocation,
      isReady: !isMissingSavedLocation,
    };
  }, [savedCity?.id, savedCountry?.id, savedRegion?.id, savedVisitorID, visitorId, hasFingerprintFailed]);

  const ipLocation = useMemo(() => {
    const { city: ipCity, country: ipCountry, continent: ipContinent, subdivisions: _subdivisions, ...rest } =
      visitorData?.ipLocation || {};

    return {
      ...rest,
      city: ipCity?.name,
      country: ipCountry?.name,
      continent: ipContinent?.name,
    };
  }, [visitorData?.ipLocation]);

  const { city, country, region, isFetched } = useVisitorLocation(ipLocation, {
    enabled: shouldFetchLocationDetails,
  });

  useEffect(() => {
    if (isFetched) {
      setVisitorLocationCookie({ city, country, region });
    }
  }, [city, country, region, isFetched]);

  if (shouldFetchLocationDetails) {
    return { ipLocation, city, country, region, isReady: isFetched, fromCookies: false };
  }

  return {
    ipLocation,
    city: savedCity,
    country: savedCountry,
    region: savedRegion,
    isReady,
    fromCookies: true,
  };
};

const useLanguageSwitchModal = ({ isTrustedBot, language, serverCookies }) => {
  const lastAccessedLanguage = serverCookies[LANGUAGE_LAST_ACCESSED] || null;
  const preferredLanguage = getCookie(LANGUAGE_PREFERRED) || serverCookies[LANGUAGE_PREFERRED] || null;
  const [isLanguageSwitchModalOpen, setIsLanguageSwitchModalOpen] = useState(false);

  const getBrowserLanguages = useCallback(() => {
    if (typeof window === 'undefined') {
      return [];
    }

    const codes = window?.navigator?.languages.map(lang => lang.split('-')[0]) || [];

    return [...new Set(codes)];
  }, []);

  useEffect(() => {
    if (isTrustedBot || language === 'keys') {
      return;
    }

    const isFirstRender = !lastAccessedLanguage;

    if (isFirstRender && typeof window !== 'undefined') {
      const browserLanguages = getBrowserLanguages();

      if (!browserLanguages.includes(language)) {
        setIsLanguageSwitchModalOpen(true);
      }
    } else if (language !== lastAccessedLanguage && language !== preferredLanguage) {
      setIsLanguageSwitchModalOpen(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSetPreferredLanguage = useCallback(langIso => {
    setCookie(LANGUAGE_PREFERRED, langIso, new Date(new Date().getTime() + EXPIRY_TIME.YEAR));
  }, []);

  const getSuggestedLanguages = useCallback(() => {
    if (typeof window === 'undefined') {
      return primaryLanguageCodes;
    }

    const browserLanguages = getBrowserLanguages();
    const userLanguageCodes = [
      ...new Set([preferredLanguage, lastAccessedLanguage, ...browserLanguages].filter(Boolean)),
    ];
    const userPreferredLanguageOptions = userLanguageCodes.map(lang => allLanguageCodes.find(l => l.iso === lang));

    const [englishOption, ...ourSuggestedLanguages] = primaryLanguageCodes;

    const validLanguages = uniqBy([englishOption, ...userPreferredLanguageOptions, ...ourSuggestedLanguages], 'iso');

    return validLanguages.slice(0, 5);
  }, [lastAccessedLanguage, preferredLanguage, getBrowserLanguages]);

  return {
    preferredLanguage,
    lastAccessedLanguage,
    getSuggestedLanguages,
    isOpen: isLanguageSwitchModalOpen,
    onSetPreferredLanguage,
  };
};

const AppContext = createContext({});

export const AppContextProvider = ({ children, sessionUser, locale, isTrustedBot, serverCookies }) => {
  const { data: visitorData, isLoading, error } = useVisitorData({ extendedResult: true });
  const hasFingerprintFailed = useMemo(() => !isLoading && (!!error || !visitorData?.visitorId), [
    error,
    isLoading,
    visitorData?.visitorId,
  ]);

  const savedVisitorID = getCookie(OB_VISITOR_ID) || serverCookies[OB_VISITOR_ID];
  const savedReqID = getCookie(OB_VISITOR_REQ_ID) || serverCookies[OB_VISITOR_REQ_ID];

  const location = useAndSaveVisitorLocation(visitorData, hasFingerprintFailed, serverCookies);

  const [scrollBarWidth, setScrollBarWidth] = useState(0);
  const [storeUserId, setLoggedInUserId] = useState(sessionUser?.id);
  const [paywallType, setPaywallType] = useState('');
  const [paywallData, setPaywallData] = useState({
    percent: '',
    wallType: '',
  });
  const [appUsageData, setAppUsageData] = useState({});

  const language = useMemo(() => {
    const serverLanguage = locale || defaultLanguage;

    if (typeof window === 'undefined') {
      return serverLanguage?.toLowerCase();
    }

    return (i18n?.language || serverLanguage)?.toLowerCase();
  }, [locale]);

  const { preferredLanguage, getSuggestedLanguages, ...languageSwitcher } = useLanguageSwitchModal({
    isTrustedBot,
    language,
    serverCookies,
  });

  useEffect(() => {
    if (hasFingerprintFailed) {
      sentryCaptureException(error, { extra: { savedVisitorID } });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFingerprintFailed]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setScrollBarWidth(window.innerWidth - document.body.clientWidth);
    }
  }, []);

  useEffect(() => {
    if ((visitorData?.visitorId && visitorData.visitorId !== savedVisitorID) || visitorData?.requestId !== savedReqID) {
      setCookie(OB_VISITOR_ID, visitorData?.visitorId);
      setCookie(OB_VISITOR_REQ_ID, visitorData?.requestId);
    }
  }, [visitorData?.visitorId, savedVisitorID, visitorData?.requestId, savedReqID]);

  const visitorId = useMemo(() => visitorData?.visitorId || savedVisitorID, [visitorData?.visitorId, savedVisitorID]);

  const appContextValue = useMemo(
    () => ({
      appUsageData,
      isTrustedBot,
      userId: storeUserId,
      isLoggedIn: !!storeUserId,
      setLoggedInUserId,
      language,
      preferredLanguage,
      getSuggestedLanguages,
      paywallType,
      setPaywallType,
      scrollBarWidth,
      visitorId,
      requestId: visitorData?.requestId,
      location,
      hasFingerprintFailed,
      setAppUsageData,
      languageSwitcher,
      paywallData,
      setPaywallData,
    }),
    [
      appUsageData,
      isTrustedBot,
      storeUserId,
      language,
      preferredLanguage,
      getSuggestedLanguages,
      paywallType,
      scrollBarWidth,
      visitorId,
      visitorData?.requestId,
      location,
      hasFingerprintFailed,
      languageSwitcher,
      paywallData,
    ],
  );

  const debugAppContext = useCallback(() => {
    const logger = console;
    const data = omitBy(appContextValue, value => typeof value === 'function');

    logger.log(data);
  }, [appContextValue]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.debugAppContext = debugAppContext;
    }
  }, [debugAppContext]);

  return <AppContext.Provider value={appContextValue}>{children}</AppContext.Provider>;
};

const useAppContext = () => useContext(AppContext);

export default useAppContext;
